body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.App {
  display: flex;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #3b86ff;
}

a:hover {
  text-decoration: underline;
}

.sr-only {
  display: none;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.leaflet-fade-anim .leaflet-tile {
  will-change: unset;
}

.leaflet-google-mutant {
  visibility: hidden !important;
}

.leaflet-control-attribution {
  visibility: hidden !important;
}

.ReactGridGallery {
  display: flex;
}

.css-1okebmr-indicatorSeparator {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
